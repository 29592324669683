import './App.css';
import TileGrid from './Components/TileGrid'
import firebase from 'firebase/app';
import 'firebase/analytics';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <TileGrid />
      </header>
    </div>
  );
}

export default App;
