import React, { useState } from 'react';
import EditableTile from './EditableTile';

const TileGrid = () => {
  const tiles = Array(6).fill(null);
  const [focusedIndex, setFocusedIndex] = useState(0); // Start focusing from the first tile
  const [focusedLine, setFocusedLine] = useState(0);

  const focusNextTile = (currentIndex) => {
    const nextIndex = (currentIndex + 1) % tiles.length;
    setFocusedIndex(nextIndex);
  };

  return (
    <div style={{ display: 'flex', gap: '5px' }}>
      <div style={{ display: 'flex', gap: '5px' }}>
      {tiles.map((_, index) => (
        <EditableTile
          key={index}
          index={index}
          focusNextTile={focusNextTile}
          isFocused={index === focusedIndex}
          lineNumber={0}
          focusedLine={focusedLine}
        />
      ))}
      </div>  
    </div>     
  );
};

export default TileGrid;
