import React, { useState, useEffect, useRef } from 'react';

const EditableTile = ({ index, focusNextTile, isFocused }) => {
  const [text, setText] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isFocused]);

  const handleInputChange = (e) => {
    if (e.target.value.length <= 1) {
      setText(e.target.value.toUpperCase());
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default Enter key behavior
      if (index < 5) {
        focusNextTile(index);
      }
    }
  };

  const handleTilesFilled = e => {

  };

  const backgroundColor = isFocused ? 'white' : (text.length > 0 ? 'green' : '#A9A9A9');

  return (
    <div 
      className="tile" 
      onClick={() => inputRef.current && inputRef.current.focus()} 
      style={{ 
        cursor: 'pointer', 
        border: '1px solid black',
        width: '50px', 
        height: '50px', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        backgroundColor: backgroundColor, // Set dynamically
        color: isFocused || text.length > 0 ? 'black' : 'white', // Adjust text color for visibility
      }}
    >
      <input
        type="text"
        value={text}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        style={{ 
          width: '100%', 
          height: '100%', 
          textAlign: 'center', 
          background: 'transparent', 
          border: 'none',
          color: 'inherit', // Inherit color from parent div
        }}
      />
    </div>
  );
};

export default EditableTile;
